import {useEffect} from 'react';

export interface ErrorParams {
  description: string
  fatal: boolean
}

function formatError(err: Error | string): string {
  if (!err)
    return ''

  if (typeof err === 'string')
    return err

  return JSON.stringify({
    message: err.message,
    name: err.name,
    stack: err.stack
  })

}

function logException(obj: ErrorParams) {
  window.gtag('event', 'exception', obj);
}


export function logExceptionFromCatch(obj: Error | string, msg?: string) {
  console.error(obj)
  logException({
    description: (!!msg ? msg + ' ' : '') + formatError(obj),
    fatal: false
  })
}


export function useGaGlobalErrorHandler() {
  useEffect(() => {

    const oldOnError = window.onerror

    const onError: OnErrorEventHandler = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error): void => {
      const err = {
        trigger: 'window.onerror',
        event,
        source,
        lineno,
        colno,
        error
      }

      console.error(err)
      logException({
        description: JSON.stringify(err),
        fatal: true
      })
      if (oldOnError)
        oldOnError(event, source, lineno, colno, error)
    }

    window.onerror = onError

    return () => {
      window.onerror = oldOnError
    }

  }, [])
}
