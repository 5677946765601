import {Api, UrlParams} from './Api'
import { Session, User } from 'models'
import {SavedSearchMetadataDto} from 'apis/SearchesApi';

const URLS = {
  login: '/login',
  me: '/user',
  password: '/password',
  recaptcha_check: '/sessions/recaptcha_check',
  change_password: '/sessions/change_password',
  accept_csv_terms: '/users/accept_csv_terms',
  master_searches: '/user/master_searches'
}

interface loginParams {
  email: string
  password: string
  recaptcha_token: string | null
}

export interface ForgotPassword extends UrlParams {
  'email': string
}

export interface ResetPassword extends UrlParams {
  'reset_password_token': string
  'password': string
  'password_confirmation': string
}
export interface ChangePassword extends UrlParams {
  'current_password': string
  'password': string
}

export interface UpdateMasterSearches extends UrlParams {
  master_search_alerts?: boolean
  master_search_transactions?: boolean
  master_search_ert?: boolean
  master_search_country_reports?: boolean
}

export interface ChangePasswordResponse {
  success: boolean
  errors: string[]
}

export interface RecaptchaCheckResponse {
  recaptcha_required: boolean
  recaptcha_site_key: string
}

export class UserApi extends Api {

  async login({email, password, recaptcha_token}: loginParams): Promise<Session> {
    return (await this.post<Session>(URLS.login, {
      email,
      password,
      recaptcha_token,
    }, {
      'X-App-Host': window.location.host
    })).data
  }

  async me(): Promise<Readonly<User>> {
    const userData = (await this.get<User>(URLS.me)).data
    const user: User = {
      ...userData,
      loggedIn: true,
      csvDownloadAllowed: userData.csv_access || userData.role === 'admin'
    }
    return user
  }

  async recaptchaCheck(): Promise<RecaptchaCheckResponse> {
   return (await this.get<RecaptchaCheckResponse>(URLS.recaptcha_check)).data
  }

  async requestPasswordReset(params: ForgotPassword): Promise<void> {
    const resp = await this.post<SavedSearchMetadataDto>(URLS.password, params);
  }

  async resetPassword(params: ResetPassword): Promise<void> {
    const resp = await this.put<SavedSearchMetadataDto>(URLS.password, params);
  }

  async changePassword(params: ChangePassword): Promise<ChangePasswordResponse> {
    const resp = (await this.put<ChangePasswordResponse>(URLS.change_password, params)).data;
    return resp
  }
  async acceptCsvTerms(): Promise<void> {
    const resp = (await this.put<ChangePasswordResponse>(URLS.accept_csv_terms)).data;
  }

  async updateMasterSearches(params: UpdateMasterSearches): Promise<void> {
    await this.post(URLS.master_searches, params)
  }

}
